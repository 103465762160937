import React from 'react';

import Amplify from 'aws-amplify';

import { Meta } from '../layout/Meta';
import { AppConfig } from '../utils/AppConfig';
import { AwsConfig } from '../utils/AwsConfig';
import { Features } from './Features';
import { Footer } from './Footer';
import { Hero } from './Hero';
import { Navbar } from './Navbar';
import { VerticalFeatures } from './VerticalFeatures';

Amplify.configure(AwsConfig);

const Base = () => (
  <div className="antialiased text-gray-600">
    <Meta title={AppConfig.title} description={AppConfig.description} />
    <Navbar />
    <Hero />
    <Features />
    <VerticalFeatures />
    <Footer />
  </div>
);

export { Base };
