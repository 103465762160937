import React from 'react';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import { Button } from '../button/Button';

type ISignInButtonProps = {
  xl?: boolean;
  children: string;
};

const SignInButton = (props: ISignInButtonProps) => {
  const handleSignIn = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Amazon });
  };

  return (
    <Button onClick={handleSignIn} xl={props.xl}>
      {props.children}
    </Button>
  );
};

export { SignInButton };
