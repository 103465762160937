import React from 'react';

import { Logo } from '../template/Logo';

type IFooterTwoRowsCopyrightProps = {
  siteName: string;
};

const FooterTwoRowsCopyright = (props: IFooterTwoRowsCopyrightProps) => (
  <>
    <div className="footer-copyright flex items-center justify-between">
      <Logo />

      <div className="text-sm text-right">
        <p>{`© Copyright ${new Date().getFullYear()} ${props.siteName}. All Rights Reserved.`}</p>
        <p>This Skill is not created by, affiliated with, or supported by Google.</p>
        <p>
          Built with ♥ by
          {' '}
          <a href="https://creativedesignsguru.com" target="_blank" rel="noopener noreferrer">
            Creative Designs Guru
          </a>
        </p>
      </div>
    </div>

    <style jsx>
      {`
        .footer-copyright :global(a) {
          @apply text-primary-500;
        }

        .footer-copyright :global(a:hover) {
          @apply underline;
        }
      `}
    </style>
  </>
);

export { FooterTwoRowsCopyright };
