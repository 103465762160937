import React from 'react';

import { Background } from '../background/Background';
import { FeatureElement } from '../feature/FeatureElement';
import { Section } from '../layout/Section';

const Features = () => (
  <Background color="bg-primary-100">
    <Section>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-16">
        <FeatureElement
          title="Create"
          icon={(
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M0 0h24v24H0z" stroke="none" />
              <path d="M5 4h4l3 3h7a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2M12 10v6M9 13h6" />
            </svg>
          )}
        >
          Create your own playlist by uploading your music on Google Drive.
        </FeatureElement>
        <FeatureElement
          title="Save"
          icon={(
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M0 0h24v24H0z" stroke="none" />
              <path d="M6 4h10l4 4v10a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2" />
              <circle cx="12" cy="14" r="2" />
              <path d="M14 4v4H8V4" />
            </svg>
          )}
        >
          Sign in on Music Home and save your playlist folder link.
        </FeatureElement>
        <FeatureElement
          title="Listen"
          icon={(
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="6" cy="17" r="3" />
              <circle cx="16" cy="17" r="3" />
              <polyline points="9 17 9 4 19 4 19 17" />
              <line x1="9" y1="8" x2="19" y2="8" />
            </svg>
          )}
        >
          Enjoy your personal playlist on your Voice assistant.
        </FeatureElement>
      </div>
    </Section>
  </Background>
);

export { Features };
