import React from 'react';

import { SignInButton } from '../auth/SignInButton';
import { Background } from '../background/Background';
import { Section } from '../layout/Section';
import { NavbarMenuCenter } from '../navigation/NavbarMenuCenter';
import { Logo } from './Logo';

const Navbar = () => (
  <Background color="bg-primary-100">
    <Section yPadding="py-6">
      <NavbarMenuCenter
        logo={<Logo xl />}
        rightMenu={(
          <>
            <li>
              <SignInButton>Sign in</SignInButton>
            </li>
          </>
        )}
      />
    </Section>
  </Background>
);

export { Navbar };
