import React from 'react';

import { SignInButton } from '../auth/SignInButton';
import { Background } from '../background/Background';
import { HeroOneButton } from '../hero/HeroOneButton';
import { Section } from '../layout/Section';

const Hero = () => (
  <Background color="bg-primary-100">
    <Section yPadding="py-24">
      <HeroOneButton
        title={(
          <>
            Create your own and
            {' '}
            <span className="text-primary-500">Personal Playlist</span>
          </>
        )}
        description={
          'The easiest way to create your personal music playlist on Google Drive.\nListen to your favorite music on your Voice assistant.'
        }
        button={<SignInButton xl>Start Creating Your Personal Playlist</SignInButton>}
      />
    </Section>
  </Background>
);

export { Hero };
