import React from 'react';

import { SignInButton } from '../auth/SignInButton';
import { Background } from '../background/Background';
import { VerticalFeatureElement } from '../feature/VerticalFeatureElement';
import { VerticalFeatureRow } from '../feature/VerticalFeatureRow';
import { Section } from '../layout/Section';

const VerticalFeatures = () => (
  <>
    <Section>
      <VerticalFeatureRow
        subtitle="Easy setup"
        title="No extra devices needed"
        image={{
          src: '/assets/images/personal-own-playlist-alexa.svg',
          alt: 'Listen to your personal and own playlist Alexa',
        }}
        reverse
      >
        <p>
          Help you to play your favorite music without the hassle. You only need a Voice assistant
          to listen to your music, nothing to install:
        </p>
        <ul className="grid grid-cols-1">
          <VerticalFeatureElement>NO need to have a server</VerticalFeatureElement>
          <VerticalFeatureElement>NO need to turn on a computer</VerticalFeatureElement>
          <VerticalFeatureElement>NO need to turn on bluetooth</VerticalFeatureElement>
          <VerticalFeatureElement>JUST need a Voice assistant</VerticalFeatureElement>
        </ul>
      </VerticalFeatureRow>
    </Section>
    <Background color="bg-primary-100">
      <Section>
        <VerticalFeatureRow
          subtitle="Personal playlist"
          title="Control to your music with Voice"
          image={{
            src: '/assets/images/music-google-drive-alexa.svg',
            alt: 'Play music from Google Drive on Alexa',
          }}
          action={<SignInButton>Get Started</SignInButton>}
        >
          <p>
            Listen to your music on your Voice assistant. Music Home is the perfect skill to create
            your own and personal music playlist.
          </p>
          <p>
            Store your personal music on Google Drive and we handle the rest. Play your own music by
            only saying &apos;
            <b>Alexa, open Get Music Home</b>
            &apos;
          </p>
        </VerticalFeatureRow>
      </Section>
    </Background>
  </>
);

export { VerticalFeatures };
